import React from 'react'
import { graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'
import Fab from '@material-ui/core/Fab';
import ArrowFowardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HotelIcon from '@material-ui/icons/Hotel';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import TrainIcon from '@material-ui/icons/Train';
import CarIcon from '@material-ui/icons/DirectionsCar';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Contents from '../../../components/contents'
import Paragraphs from '../../../components/paragraph'
import Heading from '../../../components/heading'
import HeroBox from '../../../components/herobox'
import LinkButton from '../../../components/linkbutton'

import GalleryStation from '../../../components/gallery/otsuki/station'
import GalleryLinear from '../../../components/gallery/otsuki/linear'
import GalleryMichinoeki from '../../../components/gallery/otsuki/michinoeki'
import GallerySaruhashi from '../../../components/gallery/otsuki/saruhashi'

const useStyles = makeStyles(theme => ({
    root: {

    },
    heroImageSection: {
        padding: '0px',
        margin: '0px',
    },
    rootContainer: {
        padding: 0,
    },
    pageDescriptions: {
      padding: '30px',
    },
    contentRoot: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.6)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    msButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    id: {
      marginTop: '5rem',
    },
    imageContainer: {

    },
}));

const MFGT = ({data}) => {
    const classes = useStyles();
    const language = 'ja';
    const slug = 'campaign/mt-fuji-golden-triangle';
    const pageTitle = {
      ja: '富士山ゴールデントライアングル',
      en: 'The Mt. Fuji Golden Triangle',
      zh_hant: '富士黃金三角',
      zh_hans: '富士黄金三角'
    }
    const notChild = false;
    const child = true;
    const otsuki = data.stationsJson.pages[language];
    const isawaonsen = data.railroadJson.pages[0][language];

    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle[language]} />
            <section className={classes.heroImageSection}>
                <Img objectFit="contain" fluid={data.mfgt.childImageSharp.fluid} />
            </section>
            <section>
              <div className={classes.pageDescriptions}>
                <p>富士山に行く一般的なルートは、<br />東京新宿から大月駅を経由して富士山、<br />そして同じルートで東京新宿に戻るパターン。</p>
                <p>折角の旅、同じルートの往復なんて勿体ない。<br />そこで、Jruroが自信を持ってお勧めするのが、<br />行きか帰りにバスを使って、石和を経由する三角移動！！</p>
                <ul>
                  <li>新宿&rArr;大月&rArr;富士山&rArr;石和&rArr;新宿</li>
                  <li>新宿&rArr;石和&rArr;富士山&rArr;大月&rArr;新宿</li>
                </ul>
                <p>石和には、ワイン・温泉・季節の果物<br />富士山にはない楽しい、美味しい、わくわくがいっぱい！</p>
                <p>富士山と石和のとっておきを味わう三角移動、<br />富士山ゴールデントライアングルへLet’s GO</p>
                {/* <p>日本の田舎町ぶらり旅</p>
                <p>おすすめルート</p>
                <p>日本一の富士を巡り、ワインと温泉と旬の果物を楽しむ</p>
                <p>富士山、大月駅、石和温泉駅を結ぶ三角ルート。</p>
                <p>たのしい、おいしい、わくわくがいっぱい。</p>
                <p>名付けて、富士山ゴールデントライアングル。</p> */}
                {/* <Button
                    href="#isawaonsen"
                    variant="contained"
                    endIcon={<KeyboardArrowRight />}
                    className={classes.msButton}
                >{isawaonsen.title}</Button>
                <Button
                    href="#otsuki"
                    variant="contained"
                    endIcon={<KeyboardArrowRight />}
                    className={classes.msButton}
                >{otsuki.stationName}</Button> */}
                {/* <LinkButton url="isawaonsen" title={isawaonsen.title} type="anchor" />
                <LinkButton url="otsuki" title={otsuki.stationName} type="anchor" /> */}
              </div>
            </section>
            <Container component="section" className={classes.rootContainer}>
              <div id="isawaonsen" className={classes.id}></div>
              <HeroBox
                image={data.isawaonsen.childImageSharp.fluid}
                stationName={isawaonsen.title}
                descriptions={isawaonsen.descriptions}
                language={language}
              />
              <Box component="article">{
                isawaonsen.sections.map((section, i) => {
                  if (!section.isChild) {
                      if ('article' === section.type) {
                          return (
                            <section key={i}>
                                <Heading title={section.title} isChild={section.isChild} />
                                <Contents language={language} contents={section.contents} />
                            </section>
                                  )
                      } else if ('link' === section.type) {

                      } else if ('gallery' === section.type) {

                      } else {

                      }
                  } else {

                  }
                })
              }</Box>
              <Box component="article">
                <Heading title="武田二十四将の宿" isChild={notChild} />
                <div className={classes.imageContainer}><Link to="/station/isawaonsen/takeda-24-generals"><Img objectFit="contain" fluid={data.takeda24.childImageSharp.fluid} /></Link></div>
              </Box>
              {/* <div id="otsuki" className={classes.id}></div>
              <HeroBox
                image={data.otsuki.childImageSharp.fluid}
                stationName={otsuki.stationName}
                descriptions={otsuki.descriptions}
                language={language}
              />
              <Box component="article">{
                otsuki.sections.map((section, i) => {
                  if (!section.isChild) {
                      if ('article' === section.type) {
                          if (section.link) {
                              return (
                                  <section key={i}>
                                      <Heading title={section.title} isChild={section.isChild} />
                                      <Paragraphs paragraphs={section.paragraphs} />
                                      <div className={classes.button}>
                                          <LinkButton url={section.link} title={section.title} type="external" />
                                      </div>
                                  </section>
                              )
                          } else {
                              return (
                                  <section key={i}>
                                      <Heading title={section.title} isChild={section.isChild} />
                                      <Paragraphs paragraphs={section.paragraphs} />
                                  </section>
                              )
                          }
                      } else if ('gallery' === section.type) {
                          if ('stations/otsuki/station' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GalleryStation idxSection={i} language={language} />
                                  </section>
                              )
                          } else if ('stations/otsuki/linear' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GalleryLinear idxSection={i} language={language} />
                                  </section>
                              )
                          } else if ('stations/otsuki/michinoeki' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GalleryMichinoeki idxSection={i} language={language} />
                                  </section>
                              )
                          } else if ('stations/otsuki/saruhashi' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GallerySaruhashi idxSection={i} language={language} />
                                  </section>
                              )
                          } else {

                          }
                      } else if ('link' === section.type) {

                      } else {

                      }
                  } else {
                      if ('article' === section.type) {
                          if (section.link) {
                              return (
                                  <section key={i}>
                                      <Heading title={section.title} isChild={section.isChild} />
                                      <Paragraphs paragraphs={section.paragraphs} />
                                      <div className={classes.button}>
                                          <LinkButton url={section.link} title={section.title} type="external" />
                                      </div>
                                  </section>
                              )
                          } else {
                              return (
                                  <section key={i}>
                                      <Heading title={section.title} isChild={section.isChild} />
                                      <Paragraphs paragraphs={section.paragraphs} />
                                  </section>
                              )
                          }
                      } else if ('gallery' === section.type) {
                          if ('stations/otsuki/station' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GalleryStation idxSection={i} language={language} />
                                  </section>
                              )
                          } else if ('stations/otsuki/linear' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GalleryLinear idxSection={i} language={language} />
                                  </section>
                              )
                          } else if ('stations/otsuki/michinoeki' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GalleryMichinoeki idxSection={i} language={language} />
                                  </section>
                              )
                          } else if ('stations/otsuki/saruhashi' === section.gallery.directory) {
                              return (
                                  <section className={classes.galleryContainer} key={i}>
                                      <GallerySaruhashi idxSection={i} language={language} />
                                  </section>
                              )
                          } else {

                          }
                      } else if ('link' === section.type) {
                          
                      } else {

                      }
                  }
                })
              }</Box> */}
            </Container>
            {/* <pre>{JSON.stringify(isawaonsen, null, 4)}</pre> */}
        </Layout>
    );
};

export const query = graphql`
  {
    mfgt:file(relativePath: {eq: "campaign/mfgt/enja.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    takeda24:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stationsJson(name: {eq: "otsuki"}) {
      id
      name
      lat
      lon
      route_id
      prefecture_id
      zip
      address
      heroImage
      pages {
        ja {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
        en {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
        zh_hant {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
        zh_hans {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
      }
    }
    otsuki:file(relativePath: {eq: "stations/otsuki/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
    }
    railroadJson(name: {eq: "isawaonsen"}) {
      id
      heroImage
      address {
        en {
          address1
          address2
          city
          language
          prefecture
        }
        ja {
          address1
          address2
          city
          language
          prefecture
        }
        zh_hans {
          address1
          address2
          city
          prefecture
          language
        }
        zh_hant {
          address1
          address2
          city
          language
          prefecture
        }
      }
      name
      lon
      lat
      zip
      stationName {
        en
        ja
        zh_hans
        zh_hant
      }
      route_id
      prefecture_name
      prefecture_id
      pages {
        path
        slug
        fileName
        ja {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            link
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
        en {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
        zh_hant {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
        zh_hans {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
      }
    }
    isawaonsen:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/hero.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1000, maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default MFGT;